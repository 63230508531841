var base = require('base/product/base');
var minicartTimeout;

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Displays mixed bag modal
 * @param {*} form
 */
function handleMixedBag(form) {
    if ($('#mixedBagModal').length === 0) {

        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade" id="mixedBagModal" tabindex="-1" role="dialog" aria-labelledby="mixedBagModalTitle">'
            + '<div class="modal-dialog" role="document">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <h4 class="modal-title" id="mixedBagModalTitle" aria-label="Mixed Bag Modal">In-Store Pickup</h4>'
            + '    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            + '</div>'
            + '<div class="modal-body text-center">'
            + '<p class="mb-5">Currently, we are unable to support orders where some items are picked up at the store and some are shipped to you.</p>'
            + '<p class="mb-6">Please select how you would like proceed:</p>'
            + '<div class="d-flex flex-column align-items-center" style="row-gap: 20px">'
            + '<button data-dismiss="modal" data-shipment-change="shipToHome" class="btn btn-primary">Ship All Items To Me</button>'
            + '<button data-dismiss="modal" data-shipment-change="clearAndAdd" class="btn btn-primary">Clear My Bag &amp; Add This Item</button>'
            + '<input type="hidden" value="' + encodeURIComponent(JSON.stringify(form)) +  '" />'
            + '<a class="my-7" data-dismiss="modal" href="">Cancel &amp; Continue Shopping</a>'
            + '</div>'
            + '</div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
    }
    $('#mixedBagModal').modal('show');
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response, form) {
    $('.minicart').trigger('count:update', response);

    if (response.error && response.details) {
        handleMixedBag(form);
        return;
    }
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {

        if(response.error) {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }

            $('.add-to-cart-messages').append(
                '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                + response.message
                + '</div>'
            );
        }

        $('body').trigger('cart:update');
        $('.js-minicart-main .minicart-link').trigger('click');

        minicartTimeout = setTimeout(function () {
            if(response.error) {
                $('.add-to-basket-alert').remove();
            }

            $('.minicart .js-flyout').removeClass('show', 1000, 'easeInBack')
                .trigger('flyout.hidden');
        }, 5000);
    }
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    var addPreventClass = attr.attributeId == 'color' ? true : false;
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();
        var $valueLabel = $productContainer.find('[data-attr="' + attr.id + '"] .' + attr.id + '-selected-value');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            if (attr.useDescriptionForAttributeDisplayValue) {
                $valueLabel.text(attrValue.description.toLowerCase());    
            } else {
                $valueLabel.text(attrValue.displayValue.toLowerCase());
            }
            if(addPreventClass) {
                $attrValue.parent().addClass('prevent-click');
            }
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            if(addPreventClass) {
                $attrValue.parent().removeClass('prevent-click');
            }
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color', 'length', 'size'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }
    
    if (response.product && response.product.geIsForbidden) {
        var message = "<span class='text-danger'>";
        message += response.resources.forbiddenMessage
        message += "</span>";
        $('.globale-message.forbidden').html(message);
    } else {
        $('.globale-message.forbidden').html('');
    }
    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Trigger custom event
 * @param {Object[]} imgs - Array of PDP product images,along with related information
 */
function createCarousel(imgs) {
    $('body').trigger('carousel:create', imgs);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images;
    if (!$productContainer.hasClass('product-quickview')) {
        createCarousel(primaryImageUrls, $productContainer);
    }
    // Update pricing
    if (!isChoiceOfBonusProducts && !$productContainer.hasClass('product-quickview')) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    if (!$productContainer.hasClass('product-quickview')) {
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    }
    updateAvailability(response, $productContainer);

    // Update callout message for Low Stock
    if (response.product.availability.lowstock <= 5) {
        $productContainer.find('.product-low__stock--pdp').removeClass('d-none')
        $productContainer.find('.product-low__stock--pdp').html(response.product.availability.callout)
    } else {
        $productContainer.find('.product-low__stock--pdp').addClass('d-none')
    }

    // update final sale flag
    var $finalSale = $productContainer.find('.product-final-sale');
    if($finalSale.length) {
        'finalSale' in response.product
        && response.product.finalSale
        ? $finalSale.empty().html('<span class="d-inline-block mt-3">' + response.resources.finalSale + '</span>')
        : $finalSale.empty();
    }

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer,
            resources: response.resources
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var $qty = getQuantitySelector($productContainer);
        var selectedQuantity = 1;
        var selectedUrl = quantities[0].url;
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            if (quantity.selected) {
                selectedQuantity = quantity.value;
                selectedUrl = quantity.url;
            }
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        $qty.empty().html(optionsHtml);
        $('.product-content-top .qty-number-input').data('url', selectedUrl);
    }
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        if (!$productContainer.hasClass('product-quickview')) {
            $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });
        }

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                if (!$productContainer.hasClass('product-quickview')) {
                    updateOptions(data.product.optionsHtml, $productContainer);
                }
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                if ($productContainer.hasClass('product-quickview')) {
                    $productContainer.closest('.modal-dialog').removeClass('d-none');
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

var performAddToCart = (that, formData) => {
    var scrollAnimate = require('base/components/scrollAnimate');
    var addToCartUrl;
    var pid;
    var pidsObj;
    var setPids;
    var childProducts;
    var quantity;
    var cartAddType;

    var $btn = $(that);
    if($btn.hasClass('disabled')) {
        if(!$btn.data('ready-to-order')) {
            scrollAnimate($('.product-attributes'))
        }
        return;
    }

    $('body').trigger('product:beforeAddToCart', that);

    if(!formData) {

        if ($('.set-items').length && $(that).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(that).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(that).find('.product-id').text(),
                        qty: $(that).find('.quantity-select').val(),
                        options: getOptions($(that))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = base.getPidValue($(that));
        childProducts = getChildProducts();
        quantity = getQuantitySelected($(that));
        cartAddType = $(that).data('cartaddtype') ? $(that).data('cartaddtype') : '';
    }



    var $productContainer = $(that).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(that).closest('.quick-view-dialog').find('.product-detail');
    }

    addToCartUrl = getAddToCartUrl();


    if (formData) {
        form = formData
    } else {
        form =  {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: childProducts,
            quantity: quantity,
            cartAddType: cartAddType
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }
    }

    $(that).trigger('updateAddToCartFormData', form);

    if (addToCartUrl) {
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                handlePostCartAdd(data, form);
                $('body').trigger('product:afterAddToCart', data);
                $.spinner().stop();
                base.miniCartReportingUrl(data.reportingURL);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    getQuantitySelected: getQuantitySelected,
    genericSwatchAttribute: function () {
        $(document).on(
            'click',
            '[data-attr="color"] button, [data-attr="size"] button, [data-attr="length"] button',
            function (e) {
                e.preventDefault();

                if ($(this).attr('disabled')) {
                    return;
                }
                var $productContainer = $(this).closest('.set-item');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.product-detail');
                }

                attributeSelect($(this).attr('data-url'), $productContainer);
            }
        );
    },
    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },
    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            performAddToCart(this);
        });
    },
    handleMixedBagSelection: () => {
        $(document).on('click', '#mixedBagModal .modal-body button', (e) => {
            var $selectedButton = $(e.target);
            var formData = decodeURIComponent($selectedButton.siblings('input').val());
            var shipmentChangeDecision = $selectedButton.data('shipment-change');
            if(formData && shipmentChangeDecision) {
                formData = JSON.parse(formData);
                formData.shipmentChangeFlag = shipmentChangeDecision;
                if (shipmentChangeDecision !== 'clearAndAdd') {
                    formData.productAddShippingMethod = shipmentChangeDecision;   
                }
                if(shipmentChangeDecision !== 'shipToHome') {
                    // pass currently selected ship method
                    var $selectedShippingMethod = $('.product-detail')
                        .find('.product-ship-method.selected input');

                    formData.shipmentChangeFlagMethod = $selectedShippingMethod.val();
                }
                performAddToCart(this, formData);
            }
        });
    },
    handleClearMinicartTimeout: () => {
        $('.minicart').on('minicart:cleartimeout', function(){
            if(minicartTimeout) {
                clearTimeout(minicartTimeout);
            }
        });
    }
};

Object.keys(base).forEach(function (prop) {
    // eslint-disable-next-line no-prototype-builtins
    if (!module.exports.hasOwnProperty(prop)) {
        module.exports[prop] = base[prop];
    }
});
