'use strict';

/**
 * Page Designer carousels
 */

var Splide = require('@splidejs/splide').default; //dependency include

function pageDesignerCarousels() {
    var elms = document.getElementsByClassName('pd-carousel splide');

    for ( var i = 0, len = elms.length; i < len; i++ ) {
        var options = '';
        if ($(elms[i]).hasClass('category-tiles-carousel') && window.innerWidth < 768) { // Category Tiles
            options = {
                mediaQuery: 'min',
                arrows: false,
                padding: { left: '4rem', right: '4rem' },
                gap: '2rem',
                type: 'loop',
                perPage: 1,
                perMove: 1,
                breakpoints: {
                    768: {
                      destroy: true,
                    }
                }
            }
        } else if ($(elms[i]).hasClass('shop-the-look-carousel') && $('.shop-the-look-carousel .product-tile-pd').length > 1) { // Shop the Look
            options = {
                type: 'loop',
                perPage: 1,
                perMove: 1
            }
        } else if ($(elms[i]).hasClass('journal-feature-carousel') && $('.journal-card').length > 1) { // Journal Feature Carousel
            options = {
                type: 'loop',
                perPage: 1,
                perMove: 1
            }
        } else if ($(elms[i]).hasClass('product-carousel')) { // Product Carousel
            options = {
                pagination: false,
                gap: '2rem',
                type: 'loop',
                perPage: 3,
                perMove: 1,
                breakpoints: {
                    767: {
                        pagination: true,
                        perPage: 1
                    }
                  }
            }
        } else if ($(elms[i]).hasClass('js-hero-banner-slider')) { // Hero Banner Slider
            var carouselSpeed = $(elms[i]).attr('data-carousel-speed');
            var carouselAutoPlay = $(elms[i]).attr('data-carousel-autoplay');
            if ($(elms[i]).find('.splide__slide').length > 1) {
                options = {
                    type: 'loop',
                    gap: '0',
                    perPage: 1,
                    perMove: 1,
                    autoplay: carouselAutoPlay,
                    speed: carouselSpeed,
                    arrows: true,
                    resetProgress: false,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    lazyLoad: 'nearby'
                }
            }
        } else if ($(elms[i]).hasClass('js-category-tile-slider')) { // Category Tiles
            var carouselSpeed = $(elms[i]).attr('data-carousel-speed');
            var carouselAutoPlay = $(elms[i]).attr('data-carousel-autoplay');
            if ($(elms[i]).find('.splide__slide').length <= 3 && window.innerWidth < 769) {
                options = {
                    mediaQuery: 'min',
                    arrows: false,
                    padding: { left: '4rem', right: '4rem' },
                    gap: '2rem',
                    type: 'loop',
                    perPage: 1,
                    perMove: 1,
                    autoplay: carouselAutoPlay,
                    speed: carouselSpeed,
                    resetProgress: false,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    breakpoints: {
                        769: {
                          destroy: true
                        }
                    }
                }
            } else if ($(elms[i]).find('.splide__slide').length >= 4) {
                options = {
                    mediaQuery: 'min',
                    arrows: false,
                    padding: { left: '4rem', right: '4rem' },
                    gap: '2rem',
                    type: 'loop',
                    perPage: 1,
                    perMove: 1,
                    autoplay: carouselAutoPlay,
                    speed: carouselSpeed,
                    resetProgress: false,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    breakpoints: {
                        769: {
                            arrows: true,
                            padding: '0',
                            gap: '16px',
                            type: 'loop',
                            perPage: 1,
                            perMove: 1
                        }
                    }
                }
            }
        }

        if (options !== '' && !$(elms[i]).hasClass('is-active') && !$(elms[i]).hasClass('no-splide')) {
            let splider = new Splide(elms[i], options).mount();
            // Prevent problematic behavior on mobile while the slider is in transition
            splider.on('move', function () {
                splider.Components.Drag.disable(true);
            });

            splider.on('moved', function () {
                splider.Components.Drag.disable(false);
            });
        } else {
            $(elms[i]).removeClass('is-initialized');
        }
    }
}
module.exports = {
    methods: {
        pageDesignerCarousels: pageDesignerCarousels
    },
    init: function() {
        pageDesignerCarousels();
    
        $(window).resize(function () {
            pageDesignerCarousels();
        });
    }
};